import styles from './SplashPageTopSection.module.scss'
import { SplashPageTitle } from '@/components/static/SplashPage/SplashPageTopSection/SplashPageTitle/SplashPageTitle'
import { SplashPageContainer } from '@/components/static/SplashPage/SplashPageTopSection/SplashPageContainer/SplashPageContainer'
import React, { ReactElement, useMemo } from 'react'
import { SplashPageValueProp } from '@/components/static/SplashPage/SplashPageTopSection/SplashPageValueProp/SplashPageValueProp'
import { AuthenticatorRoutingProps } from '@/components/static/Authenticator/Authenticator.types'
import { SplashPageProps } from '@/components/sharedFetchStaticProps/fetchSplashPageProps'
import { ExcitedOwl } from '@/components/static/images/owlBranding/excitedOwl'
import { CourseConfig, getCourseConfig } from 'common/src/courseConfig'

interface SplashPageTopSectionProps extends SplashPageProps {
    setIsLoginModalVisible: (isLoginModalVisible: boolean) => void
    setLoginModalRoutingPurpose: (
        loginModalRoutingPurpose: AuthenticatorRoutingProps
    ) => void
}
export const SplashTopSection: React.FC<SplashPageTopSectionProps> = (
    props
): ReactElement => {
    const courseConfig = useMemo((): CourseConfig => {
        return getCourseConfig(props.courseConfigType)
    }, [props.courseConfigType])
    return (
        <div className={styles.splashPageTopSectionContainer}>
            {courseConfig.showOwlOnSplashPage && (
                <ExcitedOwl priority={true} className={styles.landingImage} />
            )}
            <div className={styles.ellipsisOne}></div>
            <div className={styles.ellipsisTwo}></div>
            <div className={styles.ellipsisThree}></div>
            <div
                className={styles.splashPageTopSection}
                id={'cypress-splash-page'}
            >
                <div className={styles.topSectionLeft}>
                    <SplashPageTitle {...props} />
                    <div className={styles.splashPageValuePropContainer}>
                        <SplashPageValueProp
                            title={'Formula for Success'}
                            subtitle={'Personalized step-by-step study plan '}
                            icon={'star'}
                        />
                        <SplashPageValueProp
                            title={'Unparalled support'}
                            subtitle={
                                'Combination of expert AI and human instructors '
                            }
                            icon={'heart'}
                        />
                        <SplashPageValueProp
                            title={'One Stop Shop'}
                            subtitle={
                                'Everything you need in one place - no need to buy source material'
                            }
                            icon={'shoppingBag'}
                        />
                    </div>
                </div>
                <div className={styles.topSectionRight}>
                    <SplashPageContainer {...props} />
                </div>
            </div>
        </div>
    )
}
