import React, {
    ReactElement,
    useCallback,
    useState,
    useRef,
    useMemo,
    useEffect,
} from 'react'
import styles from './SplashPageSuccessRateSection.module.scss'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { usePctOnScreen } from '@/hooks/static/usePctOnScreen'
import { SplashPagePassRateProgressBar } from './SplashPagePassRateProgressBar/SplashPagePassRateProgressBar'

export const SplashPageSuccessRateSection: React.FC<
    unknown
> = (): ReactElement => {
    const [jepPct, setJepPct] = useState<number>(0)
    const [allPct, setAllPct] = useState<number>(0)
    const animate = useCallback((): void => {
        setTimeout(() => {
            setJepPct(0.7)
            setAllPct(0.46)
        }, 500)
    }, [])
    const hasAnimated = useRef<boolean>(false)
    const ref = useRef<HTMLDivElement>(null)
    const pctOnScreen = usePctOnScreen(ref)
    const shouldAnimate = useMemo(
        (): boolean => pctOnScreen < 0.9 && pctOnScreen > 0.1,
        [pctOnScreen]
    )

    useEffect(() => {
        if (hasAnimated.current) return
        if (shouldAnimate) {
            hasAnimated.current = false
            animate()
        }
    }, [animate, shouldAnimate])

    return (
        <div className={styles.successRateContainer} ref={ref}>
            <div className={styles.text}>
                Be confident&nbsp;
                <span className={styles.emphasis}>in your path</span>&nbsp;to
                success
            </div>
            <div className={styles.barChartSection}>
                <SplashPagePassRateProgressBar
                    text={'Industry Pass Rate*'}
                    percentForHeight={allPct / jepPct}
                    displayedPercent={allPct}
                    isJepPassRate={false}
                />
                <SplashPagePassRateProgressBar
                    text={'Our Pass Rate'}
                    percentForHeight={jepPct === 0 ? 0 : 1}
                    displayedPercent={jepPct}
                    isJepPassRate={true}
                />
            </div>
            <div className={`${styles.sourceReference}`}>
                <a
                    target={'_blank'}
                    href={'http://www.actuarial-lookup.com/exams/gh-dp'}
                    rel="noreferrer"
                    className={reusableCssClass.clickableText}
                >
                    * As reported by Actuarial Lookup
                </a>
            </div>
        </div>
    )
}
