import React, { ReactElement } from 'react'
import styles from './ValueProposition.module.scss'
import { reusableCssClass } from '@/utils/reusableCssClasses'

interface ValuePropositionProps {
    title: string
    description: string
    image: ReactElement
    titleFontColors: { desktop: string; mobile: string }
    isMobileMode: boolean
    backgroundColors: { desktop: string; mobile: string }
}

export const ValueProposition: React.FC<ValuePropositionProps> = (
    props
): ReactElement => {
    return (
        <div
            className={`${styles.baseValueProposition} ${reusableCssClass.centerChildrenVertically}`}
            style={{
                background: props.isMobileMode
                    ? props.backgroundColors.mobile
                    : props.backgroundColors.desktop,
            }}
        >
            <div
                className={styles.title}
                style={{
                    color: props.isMobileMode
                        ? props.titleFontColors.mobile
                        : props.titleFontColors.desktop,
                }}
            >
                {props.title}
            </div>
            <div className={styles.description}>{props.description}</div>
            <div className={styles.imageContainer}>{props.image}</div>
        </div>
    )
}
