import { useState, useEffect, useCallback } from 'react'

export interface ScrollPosition {
    scrollX: number
    scrollY: number
}

export const useScrollPosition = (): ScrollPosition => {
    const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({
        scrollX: 0,
        scrollY: 0,
    })

    const handleScroll = useCallback(() => {
        setScrollPosition({
            scrollX: window.scrollX,
            scrollY: window.scrollY,
        })
    }, [setScrollPosition])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })
        return () => window.removeEventListener('scroll', handleScroll)
    })

    return scrollPosition
}
