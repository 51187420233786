import React, { ReactElement, useMemo } from 'react'
import { SplashPageSuccessRateSection } from '@/components/static/SplashPage/SplashPageMiddleSection/SplashPageSuccessRateSection/SplashPageSuccessRateSection'
import styles from './SplashPageMiddleSection.module.scss'
import { ValueProposition } from '@/components/static/SplashPage/SplashPageMiddleSection/SplashPageSuccessRateSection/ValueProposition/ValueProposition'
import { LoomVideoPlayer } from '@/components/static/LoomVideoPlayer/LoomVideoPlayer'
import { PhilosophyOwl } from '@/components/static/images/owlBranding/philosophy'
import { Friends } from '@/components/static/images/owlBranding/friends'
import { Screenshot } from '@/components/static/images/Screenshot/Screenshot'
import { ColorMap, getJepColor } from '@/components/static/ui/consts'
import { CourseConfigType } from 'common/src/courseConfig'
import { jepAssertNever } from 'common/src/utils/jepAssertNever'
import { ErrorBoundary } from '@/components/static/ErrorBoundary/ErrorBoundary'

interface SplashPageMiddleSectionProps {
    isMobileMode: boolean
    courseConfigType: CourseConfigType
}

const getPersonalAiAssistantLoomVideoUrlForCourseConfigType = (
    courseConfigType: CourseConfigType
): string => {
    switch (courseConfigType) {
        case CourseConfigType.pharmacyTechnician:
            // TODO: Replace when we get this
            return 'https://www.loom.com/embed/957a2fa160144a309c0fa8536f9582f3?sid=daae8515-b7d3-4b6f-827e-c26e60e5b6a6'
        case CourseConfigType.fsaHealth:
            return 'https://www.loom.com/embed/957a2fa160144a309c0fa8536f9582f3?sid=daae8515-b7d3-4b6f-827e-c26e60e5b6a6'
        default:
            jepAssertNever(
                courseConfigType,
                'Unhandled courseConfigType for getPersonalAiAssistantLoomVideoUrlForCourseConfigType'
            )
    }
}

const getOneStopShopDescriptionForCourseConfigType = (
    courseConfigType: CourseConfigType
): string => {
    switch (courseConfigType) {
        case CourseConfigType.pharmacyTechnician:
            // TODO: Replace when we get this
            return 'Access our extensive inventory of outlines and practice problems from your laptop or phone - all you need is internet!'
        case CourseConfigType.fsaHealth:
            return 'Access our extensive inventory of outlines, notecards and practice problems from your laptop or phone - all you need is internet!'
        default:
            jepAssertNever(
                courseConfigType,
                'Unknown courseConfigType for getOneStopShopDescriptionForCourseConfigType'
            )
    }
}

const getSecondValuePropositionSectionForCourseConfigType = (
    courseConfigType: CourseConfigType,
    isMobileMode: boolean
): ReactElement => {
    const colors = getJepColor(courseConfigType)
    switch (courseConfigType) {
        case CourseConfigType.pharmacyTechnician:
            return (
                <ValueProposition
                    title={'Customizable quizzes with vast problem bank'}
                    description={
                        'Test yourself on exactly what you want. Or sit back and let us build your study tools for you.'
                    }
                    image={
                        <Screenshot
                            src={
                                '/splashPage/middleSection/buildANotecardSet.webp'
                            }
                            alt={'screenshot of building a notecard set'}
                        />
                    }
                    titleFontColors={{
                        desktop: colors.colorThree,
                        mobile: colors.colorFive,
                    }}
                    isMobileMode={isMobileMode}
                    backgroundColors={{
                        desktop: colors.highlight,
                        mobile: colors.lightGray,
                    }}
                />
            )
        case CourseConfigType.fsaHealth:
            return (
                <>
                    <ValueProposition
                        title={'Autograde your Quizzes and Exams'}
                        description={
                            'Our intelligent platform automatically grades your answers, providing immediate feedback to accelerate your learning'
                        }
                        image={
                            <LoomVideoPlayer
                                src={
                                    'https://www.loom.com/embed/a5eb05a6e23b4b76afd2b0281d43a664?sid=3dc50964-965e-4b59-b7cc-10b27e0fde1f'
                                }
                                aspectRatio={1.79}
                            />
                        }
                        titleFontColors={{
                            desktop: colors.colorThree,
                            mobile: colors.colorFive,
                        }}
                        isMobileMode={isMobileMode}
                        backgroundColors={{
                            desktop: colors.highlight,
                            mobile: colors.lightGray,
                        }}
                    />
                    <div className={styles.multipleValuePropositions}>
                        <ValueProposition
                            title={'Customizable Notecard Sets and Quizzes'}
                            description={
                                'Test yourself on exactly what you want. Or sit back and let us build your study tools for you.'
                            }
                            image={
                                <Screenshot
                                    src={
                                        '/splashPage/middleSection/buildANotecardSet.webp'
                                    }
                                    alt={
                                        'screenshot of building a notecard set'
                                    }
                                />
                            }
                            titleFontColors={{
                                desktop: colors.colorFive,
                                mobile: colors.colorThree,
                            }}
                            isMobileMode={isMobileMode}
                            backgroundColors={{
                                desktop: colors.highlight,
                                mobile: colors.highlight,
                            }}
                        />
                        <ValueProposition
                            title={'Social Features'}
                            description={
                                'Our cutting-edge platform fosters dynamic interactions with instructors and fellow students, promoting enriched learning.'
                            }
                            image={<Friends />}
                            titleFontColors={{
                                desktop: colors.colorFive,
                                mobile: colors.colorFive,
                            }}
                            isMobileMode={isMobileMode}
                            backgroundColors={{
                                desktop: colors.highlight,
                                mobile: colors.lightGray,
                            }}
                        />
                    </div>
                </>
            )
        default:
            jepAssertNever(
                courseConfigType,
                'Unknown courseConfigType for getSecondValuePropositionSectionForCourseConfigType'
            )
    }
}

export const SplashPageMiddleSection: React.FC<SplashPageMiddleSectionProps> = (
    props
): ReactElement => {
    const color = useMemo((): ColorMap => {
        return getJepColor(props.courseConfigType)
    }, [props.courseConfigType])

    return (
        <div className={styles.splashPageMiddleSection}>
            {props.courseConfigType === CourseConfigType.fsaHealth && (
                <SplashPageSuccessRateSection />
            )}
            <div className={styles.valuePropositionContainer}>
                <ErrorBoundary fallback={<div />}>
                    <ValueProposition
                        title={'Personal AI Assistant'}
                        description={
                            'Ask questions to your personal AI assistant and get an instant response backed by the syllabus. Not satisfied? Our experienced course instructors are available at the touch of a button.'
                        }
                        image={
                            <LoomVideoPlayer
                                src={getPersonalAiAssistantLoomVideoUrlForCourseConfigType(
                                    props.courseConfigType
                                )}
                                aspectRatio={1.78}
                            />
                        }
                        titleFontColors={{
                            desktop: color.colorThree,
                            mobile: color.colorThree,
                        }}
                        isMobileMode={props.isMobileMode}
                        backgroundColors={{
                            desktop: color.highlight,
                            mobile: color.highlight,
                        }}
                    />
                </ErrorBoundary>

                <div className={styles.multipleValuePropositions}>
                    <ErrorBoundary fallback={<div />}>
                        <ValueProposition
                            title={'One Stop Shop Accessible Anywhere'}
                            description={getOneStopShopDescriptionForCourseConfigType(
                                props.courseConfigType
                            )}
                            image={<PhilosophyOwl />}
                            titleFontColors={{
                                desktop: color.colorFive,
                                mobile: color.colorFive,
                            }}
                            isMobileMode={props.isMobileMode}
                            backgroundColors={{
                                desktop: color.highlight,
                                mobile: color.lightGray,
                            }}
                        />
                    </ErrorBoundary>
                    <ErrorBoundary fallback={<div />}>
                        <ValueProposition
                            title={'Personalized Study Plan'}
                            description={
                                "We recommend a unique action plan each day based on your progress and exam timeline. Focus on studying, and we'll handle the rest."
                            }
                            image={
                                <Screenshot
                                    src={
                                        '/splashPage/middleSection/studyPlanDashboardScreenshot.webp'
                                    }
                                    alt={
                                        'screenshot of recommended action in dash'
                                    }
                                />
                            }
                            titleFontColors={{
                                desktop: color.colorFive,
                                mobile: color.colorThree,
                            }}
                            isMobileMode={props.isMobileMode}
                            backgroundColors={{
                                desktop: color.highlight,
                                mobile: color.highlight,
                            }}
                        />
                    </ErrorBoundary>
                </div>
                {getSecondValuePropositionSectionForCourseConfigType(
                    props.courseConfigType,
                    props.isMobileMode
                )}
            </div>
        </div>
    )
}
