import React, { ReactElement } from 'react'
import styles from './SplashPageCTA.module.scss'
import {
    CTAButton,
    CTAButtonType,
} from '@/components/static/ui/Button/CTAButton/CTAButton'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { reusableCssClass } from '@/utils/reusableCssClasses'

export const SplashPageCTA: React.FC<unknown> = (): ReactElement => {
    return (
        <>
            <div
                className={`${styles.splashPageCTAContainer} ${reusableCssClass.centerChildrenVertically}`}
            >
                <div className={`${styles.splashPageCTA}`}>
                    <div className={`${styles.header}`}>
                        Subscribe now to gain access to all our study materials
                    </div>
                    <div
                        className={`${styles.ctaButton} ${reusableCssClass.centerChildrenVertically}`}
                    >
                        <CTAButton
                            href={UiLayoutConstant.COURSES_PAGE}
                            type={CTAButtonType.greenDarkBackground}
                            text={`Let's Go`}
                            onClick={null}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
