import React, { ReactElement, useMemo, useState } from 'react'
import {
    getSplashPagePeopleConfigForCourseConfigType,
    SplashPagePeopleConfig,
} from './SplashPagePerson/SplashPagePeopleConfig'
import { SplashPagePerson } from './SplashPagePerson/SplashPagePerson'
import { SplashPagePersonDescription } from '@/components/static/SplashPage/SplashPageTeam/SplashPagePersonDescription/SplashPagePersonDescription'
import {
    SplashPagePersonInfo,
    SplashPagePersonName,
} from '@/components/static/SplashPage/SplashPageTeam/SplashPagePerson/SplashPagePerson.types'
import styles from './SplashPageTeam.module.scss'
import { useWindowSize } from '@/hooks/useWindowSize'
import ChevronLeft from '../../../icons/ChevronLeft.svg'
import ChevronRight from '../../../icons/ChevronRight.svg'
import Dot from '../../../icons/Dot.svg'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import SwipeableViews from 'react-swipeable-views'
import { CourseConfigType } from 'common/src/courseConfig'

export const SplashPageTeam: React.FC<{
    courseConfigType: CourseConfigType
}> = (props): ReactElement => {
    const [activePersonOrJob, setActivePersonOrJob] =
        useState<SplashPagePersonName>(null)
    const [displayedIndex, setDisplayedIndex] = useState<number>(0)
    const windowSize = useWindowSize()

    const splashPagePeopleConfig = useMemo(
        (): SplashPagePeopleConfig =>
            getSplashPagePeopleConfigForCourseConfigType(
                props.courseConfigType
            ),
        [props.courseConfigType]
    )
    const arrayOfDisplayedElements = useMemo((): ReactElement[] => {
        let numberOnScreen = 4
        if (windowSize.width < 800) {
            numberOnScreen = 2
        }
        const numberOfPeople = Object.keys(splashPagePeopleConfig).length
        const numberOfDots = Math.ceil(numberOfPeople / numberOnScreen)
        const displayedArray: ReactElement[] = []
        for (let idx = 0; idx < numberOfDots; idx++) {
            const displayedSubarray = Object.entries(splashPagePeopleConfig)
                .slice(idx * numberOnScreen, (idx + 1) * numberOnScreen)
                .map(([person, splashPagePersonConfig]) => (
                    <SplashPagePerson
                        key={person}
                        info={splashPagePersonConfig}
                        setAsActivePerson={() =>
                            setActivePersonOrJob(person as SplashPagePersonName)
                        }
                        isActivePerson={activePersonOrJob === person}
                    />
                ))
            displayedArray.push(
                <div className={styles.subPeople} key={idx}>
                    {displayedSubarray}
                </div>
            )
        }
        return displayedArray
    }, [activePersonOrJob, splashPagePeopleConfig, windowSize.width])

    const dotSelector = useMemo((): ReactElement[] => {
        const numDots = arrayOfDisplayedElements.length
        const dots: ReactElement[] = []
        for (let idx = 0; idx < numDots; idx++) {
            dots.push(
                <div
                    key={idx}
                    className={`${
                        idx === displayedIndex ? styles.active : styles.base
                    }`}
                    onClick={() => {
                        setDisplayedIndex(idx)
                        setActivePersonOrJob(null)
                    }}
                >
                    <Dot />
                </div>
            )
        }
        return dots
    }, [arrayOfDisplayedElements.length, displayedIndex])

    const activePersonDetails: ReactElement = useMemo((): ReactElement => {
        if (!activePersonOrJob) return null
        const info: SplashPagePersonInfo =
            splashPagePeopleConfig[activePersonOrJob]
        return <SplashPagePersonDescription info={info} />
    }, [activePersonOrJob, splashPagePeopleConfig])

    const leftArrowClassName = useMemo((): string => {
        if (displayedIndex === 0) {
            return reusableCssClass.dontTouchMe
        }

        return reusableCssClass.clickMe
    }, [displayedIndex])

    const rightArrowClassName = useMemo((): string => {
        if (displayedIndex >= arrayOfDisplayedElements.length - 1) {
            return reusableCssClass.dontTouchMe
        }

        return reusableCssClass.clickMe
    }, [arrayOfDisplayedElements.length, displayedIndex])

    return (
        <div className={styles.splashPageTeam}>
            <div className={styles.title}>
                Meet the&nbsp;<span className={styles.emphasis}>Team</span>
            </div>
            <div className={styles.carouselContainer}>
                <div
                    onClick={() => {
                        displayedIndex > 0
                            ? setDisplayedIndex(displayedIndex - 1)
                            : ''
                        setActivePersonOrJob(null)
                    }}
                    className={reusableCssClass.centerChildrenVertically}
                >
                    <div
                        className={`${styles.arrowContainer} ${leftArrowClassName}`}
                    >
                        <ChevronLeft />
                    </div>
                </div>

                <div className={styles.displayedPeopleContainer}>
                    <SwipeableViews
                        index={displayedIndex}
                        onChangeIndex={setDisplayedIndex}
                        slideClassName={`${reusableCssClass.sliderContainer}`}
                    >
                        {arrayOfDisplayedElements}
                    </SwipeableViews>
                </div>
                <div
                    onClick={() => {
                        displayedIndex < arrayOfDisplayedElements.length - 1
                            ? setDisplayedIndex(displayedIndex + 1)
                            : ''
                        setActivePersonOrJob(null)
                    }}
                    className={reusableCssClass.centerChildrenVertically}
                >
                    <div
                        className={`${styles.arrowContainer} ${rightArrowClassName}`}
                    >
                        <ChevronRight />
                    </div>
                </div>
            </div>
            <div className={styles.dotContainer}>{dotSelector}</div>
            <div className={styles.teamMemberDetails}>
                {activePersonDetails}
            </div>
        </div>
    )
}
