import React, { ReactElement } from 'react'
import styles from './LoomVideoPlayer.module.scss'

export const LoomVideoPlayer: React.FC<{ src: string; aspectRatio: number }> = (
    props
): ReactElement => {
    return (
        <span
            className={styles.loomContainer}
            style={{
                aspectRatio: props.aspectRatio,
            }}
        >
            <iframe allowFullScreen={true} src={props.src} frameBorder="0" />
        </span>
    )
}
