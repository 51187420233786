import { useState, useCallback } from 'react'

export interface ExponentialDecayParameters {
    initialValue: number
    exponentialBase: number
    maximumValue: number
}

/*
Returns a number that scales exponentially every time the returned updateFunc is run
Once the number surpasses the maximum value, it is set to null
Intended to be used along with the useInterval hook
 */
export const useExponentialDecay = (
    params: ExponentialDecayParameters
): [number | null, () => void] => {
    const { initialValue, exponentialBase, maximumValue } = params
    const [currentIntervalMS, setCurrentIntervalMS] = useState<number | null>(
        initialValue
    )

    const updateInterval = useCallback(() => {
        setCurrentIntervalMS((currentIntervalMS) => {
            const newInterval = exponentialBase * currentIntervalMS
            if (newInterval > maximumValue) return null
            return newInterval
        })
    }, [exponentialBase, maximumValue])

    return [currentIntervalMS, updateInterval]
}
