import styles from './SplashPagePerson.module.scss'
import React, { ReactElement, useCallback, useState } from 'react'
import { SplashPagePersonProps } from './SplashPagePerson.types'
import { reusableCssClass } from '@/utils/reusableCssClasses'

export const SplashPagePerson: React.FC<SplashPagePersonProps> = (
    props
): ReactElement => {
    const [isHover, setIsHover] = useState<boolean>(false)
    const setAsActivePerson = useCallback((): void => {
        props.setAsActivePerson()
    }, [props])

    const { info } = props

    return (
        <div
            className={`${styles.splashPagePerson} ${
                props.isActivePerson && styles.splashPagePersonActive
            } ${reusableCssClass.clickMe} ${isHover ? styles.hover : ''}`}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={setAsActivePerson}
        >
            {info.imageComponent}
        </div>
    )
}
