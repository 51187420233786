import {
    ExponentialDecayParameters,
    useExponentialDecay,
} from '@/hooks/useExponentialDecay'
import { useInterval } from '@/hooks/static/useInterval'

export const useIntervalWithExponentialDecay = (
    callback: () => void,
    params: ExponentialDecayParameters
): void => {
    const [exponentialDecayIntervalMS, updateExponentialDecayInterval] =
        useExponentialDecay(params)
    useInterval(() => {
        callback()
        updateExponentialDecayInterval()
    }, exponentialDecayIntervalMS)
}
