import { MutableRefObject, useEffect, useState } from 'react'
import { useWindowSize } from '@/hooks/useWindowSize'
import { useScrollPosition } from '@/hooks/static/useScrollPosition'
import { useIntervalWithExponentialDecay } from '@/hooks/static/useIntervalWithExponentialDecay'

interface PctOnScreenData {
    top: number
    bottom: number
    height: number
}
const calcPctOnScreen = (
    pctOnScreenData: PctOnScreenData,
    windowSizeHeight: number
): number =>
    Math.min(
        1,
        Math.max(
            0,
            1 -
                pctOnScreenData.bottom /
                    (windowSizeHeight + pctOnScreenData.height)
        )
    )

export const getRectFromRef = (
    ref: MutableRefObject<HTMLDivElement>
): PctOnScreenData => {
    return (
        ref.current?.getBoundingClientRect() || {
            top: 0,
            bottom: 0,
            height: 0,
        }
    )
}

export const usePctOnScreen = (
    ref: MutableRefObject<HTMLDivElement>
): number => {
    const { height } = useWindowSize()
    const { scrollY } = useScrollPosition()

    const [pctOnScreen, setPctOnScreen] = useState<number>(0)
    const updatePctOnScreen = (): void => {
        const rect = getRectFromRef(ref)
        const newPctOnScreen = calcPctOnScreen(rect, height)
        setPctOnScreen(newPctOnScreen)
    }
    useEffect(() => {
        updatePctOnScreen()
    }, [height, ref, scrollY]) // intentionally set this way

    useIntervalWithExponentialDecay(updatePctOnScreen, {
        initialValue: 50,
        exponentialBase: 10,
        maximumValue: 5_000,
    })

    return pctOnScreen
}
