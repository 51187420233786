import styles from './SplashPagePersonDescription.module.scss'
import React, { ReactElement } from 'react'
import { SplashPagePersonDescriptionProps } from './SplashPagePersonDescription.types'
import { reusableCssClass } from '@/utils/reusableCssClasses'

export const SplashPagePersonDescription: React.FC<
    SplashPagePersonDescriptionProps
> = (props): ReactElement => {
    const { info } = props

    return (
        <div className={styles.splashPagePersonDescription}>
            <div className={styles.splashPagePersonDescriptionHeader}>
                <div
                    className={`${styles.splashPagePersonDescriptionHeaderFirstName}`}
                >
                    <a
                        href={info.personalLinkedInUrl}
                        target={'_blank'}
                        rel={'noreferrer'}
                        className={reusableCssClass.clickableText}
                    >
                        {info.firstName}
                    </a>
                </div>
                <div
                    className={
                        styles.splashPagePersonDescriptionHeaderJepJobTitle
                    }
                >
                    {info.jepJobTitle} @ Just Enough Prep
                </div>
                <div
                    className={
                        styles.splashPagePersonDescriptionHeaderCurrentEmployer
                    }
                >
                    {info.currentEmployerJobTitle} @ {info.currentEmployer}
                </div>
            </div>
            <div className={styles.splashPagePersonDescriptionBody}>
                {info.description}
            </div>
        </div>
    )
}
