import styles from './SplashPageValueProp.module.scss'
import React, { ReactElement, useMemo } from 'react'
import Star from '../../../../icons/star.svg'
import Heart from '../../../../icons/heart.svg'
import ShoppingBag from '../../../../icons/shoppingBag.svg'
import { jepAssertNever } from 'common/src/utils/jepAssertNever'

interface SplashPageValueProp {
    title: string
    subtitle: string
    icon: 'star' | 'heart' | 'shoppingBag'
}
export const SplashPageValueProp: React.FC<SplashPageValueProp> = (
    props
): ReactElement => {
    const icon = useMemo((): ReactElement => {
        let icon: ReactElement
        switch (props.icon) {
            case 'star':
                icon = <Star />
                break
            case 'heart':
                icon = <Heart />
                break
            case 'shoppingBag':
                icon = <ShoppingBag />
                break
            default:
                jepAssertNever(
                    props.icon,
                    'Unhandled icon to get SplashPageValueProp'
                )
        }
        return icon
    }, [props.icon])
    return (
        <div className={styles.valueProp}>
            <div className={styles.titleAndIcon}>
                <div className={styles.icon}>{icon}</div>
                <div className={styles.title}>{props.title}</div>
            </div>
            <div className={styles.subtitle}>{props.subtitle}</div>
        </div>
    )
}
