import { DateTime } from 'luxon'

enum SittingSeason {
    spring = 'Spring',
    fall = 'Fall',
}

// crude, but should do the trick no problem
const getSittingSeasonForDate = (date: Date): SittingSeason => {
    if (date.getUTCMonth() <= 6) return SittingSeason.spring
    return SittingSeason.fall
}

const getSittingYearForDate = (date: Date): number => date.getFullYear()

// takes the form of: "[Sitting Season] [Year]", e.g. "Spring 2023"
export const getSittingTextForDate = (date: Date | string): string => {
    date = new Date(date)
    return `${getSittingSeasonForDate(date)} ${getSittingYearForDate(date)}`
}

// takes the form of "[Month Abbrev] [Year]", e.g. "Dec 2022"
export const getReleaseDateTextForDate = (date: Date | string): string => {
    date = new Date(date)
    return `${DateTime.fromJSDate(date).toFormat(
        'LLL'
    )} ${getSittingYearForDate(date)}`
}

const DEFAULT_TIME_ZONE = 'America/New_York'
// takes the form of "January 3, 2023"
export const getPrettyFormattedDateTextForDate = (
    date: Date | string
): string => {
    date = new Date(date)

    return `${DateTime.fromJSDate(date, { zone: DEFAULT_TIME_ZONE }).toFormat(
        'MMMM d, yyyy'
    )}`
}
