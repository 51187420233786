import React, { PropsWithChildren, ReactElement, CSSProperties } from 'react'
import styles from './FadeInOut.module.scss'

export const FadeInOut: React.FC<
    PropsWithChildren<{
        fadeInDurationInMs: number
        fadeOutDurationInMs: number
        fadeOutDelayInMs?: number
        className?: string
        isVisible: boolean
        style?: CSSProperties
    }>
> = (props): ReactElement => {
    const style = props.style ?? {}
    return (
        <span
            className={`${styles.fadeInOut} ${
                props.isVisible ? styles.visible : styles.notVisible
            } ${props.className}`}
            style={{
                ...style,
                transition: `all ${
                    props.isVisible
                        ? props.fadeInDurationInMs
                        : props.fadeOutDurationInMs
                }ms ease ${
                    !props.isVisible && props.fadeOutDelayInMs
                        ? `${props.fadeOutDelayInMs}ms`
                        : ''
                }`,
            }}
        >
            {props.children}
        </span>
    )
}
