import styles from './SplashPageTopCTAButtons.module.scss'
import {
    CTAButton,
    CTAButtonType,
} from '@/components/static/ui/Button/CTAButton/CTAButton'
import { NotificationKeys } from '@/utils/notificationKeys'
import {
    AuthenticatorOpenPurpose,
    AuthenticatorRoutingProps,
} from '@/components/static/Authenticator/Authenticator.types'
import React, { ReactElement } from 'react'
import { notification } from 'antd'
import { ALL_ACCESS_TYPES } from 'common/src/api/websiteFrontendVsWebsiteBackend/accountMaintenance/types'

export const SplashPageTopCTAButtons: React.FC<{
    courseName: string
    setIsLoginModalVisible: (isLoginModalVisible: boolean) => void
    setLoginModalRoutingPurpose: (
        loginModalRoutingPurpose: AuthenticatorRoutingProps
    ) => void
    ctaButtonContainerClassName?: string
}> = (props): ReactElement => {
    return (
        <div
            className={`${styles.splashPageContainerCTAButtonContainer} ${
                props.ctaButtonContainerClassName ?? ''
            }`}
        >
            <div className={styles.splashPageContainerBuyButtonContainer}>
                <CTAButton
                    type={CTAButtonType.defaultBorderDarkBackground}
                    onClick={() => {
                        notification.info({
                            message: 'One last thing.',
                            description:
                                'Please sign up or log in before proceeding with your purchase.',
                            key: NotificationKeys.MUST_LOGIN_TO_PURCHASE,
                            duration: null,
                        })

                        props.setLoginModalRoutingPurpose({
                            purpose: AuthenticatorOpenPurpose.PURCHASE,
                            courseName: props.courseName,
                            accessTypes: ALL_ACCESS_TYPES,
                        })
                        props.setIsLoginModalVisible(true)
                    }}
                    text={'Buy Now'}
                />
            </div>
            <div className={styles.splashPageContainerDemoButtonContainer}>
                <CTAButton
                    type={CTAButtonType.primaryDarkBackground}
                    onClick={() => {
                        notification.info({
                            message: 'One last thing.',
                            description:
                                'Please sign up or log in before proceeding with your demo.',
                            key: NotificationKeys.MUST_LOGIN_TO_DEMO,
                            duration: null,
                        })

                        props.setLoginModalRoutingPurpose({
                            purpose: AuthenticatorOpenPurpose.DEMO,
                            courseName: props.courseName,
                            accessTypes: null,
                        })
                        props.setIsLoginModalVisible(true)
                    }}
                    text={'Free Trial'}
                />
                <div className={styles.splashPageContainerDemoButtonSubtext}>
                    (7 days)
                </div>
            </div>
        </div>
    )
}
