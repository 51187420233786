import React, { ReactElement, useMemo } from 'react'
import styles from './SplashPageContainerInfo.module.scss'
import { getPrettyFormattedDateTextForDate } from '@/components/static/SplashPage/dateUtils'

interface SplashPageContainerInfoProps {
    prettyCourseName: string
    numPracticeProblems: number
    numNotecards: number
    numOutlines: number
    nextSittingDate: Date
}

export const SplashPageContainerInfo: React.FC<SplashPageContainerInfoProps> = (
    props
): ReactElement => {
    const isValidNextSittingDate = useMemo((): boolean => {
        return props.nextSittingDate > new Date()
    }, [props.nextSittingDate])
    return (
        <div
            className={styles.splashPage2ContainerInfoContainer}
            id={'cypress-splash-page'}
        >
            <div className={styles.containerInfoTitleContainer}>
                <div className={styles.containerInfoTitle}>
                    {props.prettyCourseName}
                </div>
                {isValidNextSittingDate && (
                    <div
                        className={styles.containerInfoSubtitle}
                    >{`Next Sitting: ${getPrettyFormattedDateTextForDate(
                        props.nextSittingDate
                    )}`}</div>
                )}
            </div>

            <div className={styles.containerInfoText}>
                <ul style={{ whiteSpace: 'nowrap', width: '0px' }}>
                    <li>{props.numPracticeProblems} practice questions</li>
                    <li>{props.numNotecards} notecards</li>
                    <li>Comprehensive outlines</li>
                </ul>
            </div>
        </div>
    )
}
