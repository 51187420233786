import React, { PropsWithChildren, ReactElement } from 'react'
import { LinkItemProps } from '@/components/static/ui/LinkItem/LinkItem.types'
import Link from 'next/link'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import styles from './LinkItem.module.scss'

export const LinkItem: React.FC<PropsWithChildren<LinkItemProps>> = (
    props
): ReactElement => {
    return (
        <Link
            href={props.href}
            className={`${
                props.underline
                    ? reusableCssClass.clickableText
                    : reusableCssClass.clickableText
            } ${styles.linkItem} ${props.inline && styles.inline} ${
                props.className ?? ''
            }`}
        >
            {props.children}
        </Link>
    )
}
