import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styles from './SplashPagePassRateProgressBar.module.scss'
import { REM_IN_PX } from '@/utils/constants'
import { FadeInOut } from '@/components/static/FadeInOut/FadeInOut'
import CountUp from 'react-countup'

interface SplashPagePassRateProgressBarProps {
    text: string
    percentForHeight: number
    displayedPercent: number
    isJepPassRate: boolean
}

const BASE_HEIGHT_IN_REM = 20
export const SplashPagePassRateProgressBar: React.FC<
    SplashPagePassRateProgressBarProps
> = (props): ReactElement => {
    const [effectivePct, setEffectivePct] = useState<number>(0)
    const [isVisible, setIsVisible] = useState<boolean>(false)
    useEffect(() => {
        setTimeout(() => setEffectivePct(props.percentForHeight), 0)
    }, [props.percentForHeight])
    useEffect(() => {
        if (effectivePct > 0) {
            // text fades in as the bars get high enough to show the text
            setTimeout(() => setIsVisible(true), 750)
        }
    }, [effectivePct])

    const containerRef = useRef<HTMLDivElement>(null)
    const animatedNumberRef = useRef<HTMLDivElement>(null)

    return (
        <div className={styles.passRateContainer}>
            <div className={styles.outer}>
                <div className={styles.middle}>
                    <div
                        className={styles.inner}
                        ref={containerRef}
                        style={{
                            height: `${
                                animatedNumberRef.current?.clientHeight /
                                    REM_IN_PX +
                                BASE_HEIGHT_IN_REM
                            }rem`,
                        }}
                    >
                        <div
                            className={`${styles.barChart} ${
                                props.isJepPassRate
                                    ? styles.ourPassRate
                                    : styles.theirPassRate
                            }`}
                            style={{
                                height: `${
                                    effectivePct * BASE_HEIGHT_IN_REM
                                }rem`,
                            }}
                        >
                            <div
                                className={`${styles.barChartText} ${
                                    props.isJepPassRate
                                        ? styles.ours
                                        : styles.theirs
                                }`}
                            >
                                <FadeInOut
                                    fadeInDurationInMs={300}
                                    fadeOutDurationInMs={0}
                                    isVisible={isVisible}
                                >
                                    {props.text}
                                </FadeInOut>
                            </div>
                        </div>
                        <div
                            className={styles.passRateNumber}
                            ref={animatedNumberRef}
                        >
                            <CountUp
                                end={props.displayedPercent * 100}
                                duration={1.5}
                                decimals={0}
                                preserveValue={true}
                            />
                            %
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
