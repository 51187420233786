import React, { ReactElement } from 'react'
import styles from './Screenshot.module.scss'
import Image from 'antd/lib/image'
import { ScreenshotProps } from '@/components/static/images/Screenshot/Screenshot.types'

export const Screenshot: React.FC<ScreenshotProps> = (props): ReactElement => {
    return (
        <div className={styles.screenshot}>
            <Image src={props.src} alt={props.alt} />
        </div>
    )
}
