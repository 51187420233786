import React, { ReactElement, ReactNode, useMemo, useState } from 'react'
import styles from './SplashPageContainer.module.scss'
import SwipeableViews from 'react-swipeable-views'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { SplashPageContainerInfo } from '@/components/static/SplashPage/SplashPageTopSection/SplashPageContainer/SplashPageContainerInfo/SplashPageContainerInfo'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { LinkItem } from '@/components/static/ui/LinkItem/LinkItem'
import { CourseInfo, ReleaseStatus } from 'common/src/api/core/courses/types'
import { AuthenticatorRoutingProps } from '@/components/static/Authenticator/Authenticator.types'
import { SplashPageProps } from '@/components/sharedFetchStaticProps/fetchSplashPageProps'
import { buildFrontendLogger } from '@/logging/FrontendLogger'
import { SplashPageTopCTAButtons } from '@/components/static/SplashPageSharedComponents/SplashPageTopCTAButtons/SplashPageTopCTAButtons'

interface SplashPageTopSectionContainerProps extends SplashPageProps {
    setIsLoginModalVisible: (isLoginModalVisible: boolean) => void
    setLoginModalRoutingPurpose: (
        loginModalRoutingPurpose: AuthenticatorRoutingProps
    ) => void
}

const logger = buildFrontendLogger('SplashPageTopSectionContainer')

export const SplashPageContainer: React.FC<
    SplashPageTopSectionContainerProps
> = (props): ReactElement => {
    const [buttonIndex, setButtonIndex] = useState<number>(0)
    const fullyReleasedCourseInfo = useMemo((): CourseInfo[] => {
        logger.info(`CourseInfo: ${JSON.stringify(props.coursesInfo)}`)
        logger.info(`props: ${JSON.stringify(props)}`)
        return props.coursesInfo.filter(
            (courseInfo) =>
                courseInfo.releaseInfo.releaseStatus ===
                ReleaseStatus.fullyAvailable
        )
    }, [props])
    const courseButtons = useMemo((): ReactElement[] => {
        return fullyReleasedCourseInfo.map((courseInfo, index) => (
            <div
                key={index}
                className={`${styles.splashPageContainerButton} ${
                    buttonIndex === index ? styles.isActive : ''
                }`}
                onClick={() => setButtonIndex(index)}
            >
                {`GH ${courseInfo.abbreviation}`}
            </div>
        ))
    }, [buttonIndex, fullyReleasedCourseInfo])

    const courseInfoViews = useMemo((): ReactNode => {
        return fullyReleasedCourseInfo.map((courseInfo, index) => (
            <SplashPageContainerInfo
                key={index}
                prettyCourseName={courseInfo.prettyName}
                numNotecards={courseInfo.contentStats.numNotecards}
                numOutlines={courseInfo.contentStats.numOutlines}
                numPracticeProblems={
                    courseInfo.contentStats.numPracticeProblems
                }
                nextSittingDate={courseInfo.nextSittingDate}
            />
        ))
    }, [fullyReleasedCourseInfo])
    return (
        <div>
            <div className={styles.splashPageContainer}>
                <div className={styles.text}>
                    <div className={styles.splashPageContainerTitle}>
                        {fullyReleasedCourseInfo?.length && props.courseTrack}
                    </div>
                    {fullyReleasedCourseInfo.length > 1 && (
                        <div className={styles.splashPageContainerSubTitle}>
                            Select your test
                        </div>
                    )}
                    {fullyReleasedCourseInfo.length > 1 && (
                        <div
                            className={
                                styles.splashPageContainerButtonContainer
                            }
                        >
                            {courseButtons}
                        </div>
                    )}
                    <div className={styles.splashPageContainerContentContainer}>
                        <SwipeableViews
                            index={buttonIndex}
                            onChangeIndex={setButtonIndex}
                            slideClassName={reusableCssClass.sliderContainer}
                        >
                            {courseInfoViews}
                        </SwipeableViews>
                    </div>
                </div>
                <SplashPageTopCTAButtons
                    courseName={fullyReleasedCourseInfo[buttonIndex].name}
                    setIsLoginModalVisible={props.setIsLoginModalVisible}
                    setLoginModalRoutingPurpose={
                        props.setLoginModalRoutingPurpose
                    }
                />
            </div>
            <div className={styles.splashPageSmallTextContainer}>
                <LinkItem
                    href={{
                        pathname: UiLayoutConstant.COURSES_PAGE,
                    }}
                    className={styles.splashPageSmallText}
                >
                    View all our exams and plans
                </LinkItem>
            </div>
        </div>
    )
}
