import React, { ReactElement } from 'react'
import styles from './SplashPageTitle.module.scss'
import { SplashPageProps } from '@/components/sharedFetchStaticProps/fetchSplashPageProps'
import { getCourseConfig } from 'common/src/courseConfig'

export const SplashPageTitle: React.FC<SplashPageProps> = (
    props
): ReactElement => {
    const courseConfig = getCourseConfig(props.courseConfigType)
    return (
        <div className={styles.text}>
            <span className={styles.titleText}>
                <span className={styles.emphasis}>{courseConfig.name}</span> to
                pass your exam.
            </span>
            <div className={styles.subtitleText}>
                Ace your {props.courseTrack} exams using our digital
                analytics-driven platform
            </div>
        </div>
    )
}
